(function($) {

  $('.js-slider').slick({
    autoplay: true,
    autoplaySpeed: 6000,
    centerMode: true,
    dots: false,
    centerPadding: '160px',
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
        autoplay: false,
        breakpoint: 991,
        arrows: true,
        settings: {
          centerPadding: '60px',
        }
      },
      {
        autoplay: true,
        breakpoint: 767,
        arrows: false,
        settings: {
          centerPadding: '40px',
        }
      },
      {
        autoplay: true,
        breakpoint: 575,
        arrows: false,
        settings: {
          centerPadding: '20px',
        }
      }
    ]
  });

})(jQuery);
